<template>
    <div class="store-search-container col-md-12 col-9">
        <div class="store-search row no-gutters align-items-center height-xs--inherit">
            <div class="store-search-content col-md-12 height-xs--6">
                <div class="height-xs--inherit row align-items-center no-gutters">
                    <div
                        class="store-search__filter-header col-3"
                        @click.stop="locationListEnabled = false; airportListEnabled = !airportListEnabled"
                    >
                        <span class="store-search__filter-header-text">{{ activeAirport.airportIdent }}</span>
                    </div>

                    <div
                        v-if="airportListEnabled"
                        ref="airport_dropdown_container"
                        class="store-search__filter-list col-12 shadow--2 dropdown"
                    >
                        <li
                            class="store-search__list-item store-search__filter-link"
                            @click.stop="setAirport(baseAirport)"
                        >
                            <span>{{ baseAirport.airportIdent }}</span>
                        </li>

                        <template v-if="airports.length">
                            <li
                                v-for="airport in airports"
                                :key="airport.id"
                                :data-index="airport.id"
                                class="store-search__list-item store-search__filter-link"
                                @click.stop="setAirport(airport)"
                            >
                                <div class="d-flex"><div>{{ getAirportString(airport) }}</div></div>
                            </li>
                        </template>
                        <div v-else>
                            Nothing here.
                        </div>
                    </div>

                    <div
                        class="store-search__item-header col-9"
                        @click.stop="airportListEnabled = false; locationListEnabled = !locationListEnabled"
                    >
                        <span class="store-search__item-header-text">
                            {{ activeLocation.name }}
                            <template v-if="activeLocation.terminal"> - {{ activeLocation.terminal }}</template>
                        </span>
                    </div>

                    <div 
                        v-if="locationListEnabled" 
                        class="store-search__item-list col-12 offset-md-3 shadow--2"
                    >
                        <div class="row">
                            <div class="col-12">
                                <div class="store-search__list-item">
                                    <form-input
                                        ref="input"
                                        v-model="query"
                                        bare="true"
                                        autofocus
                                        @click.native.stop="() => true"
                                    />
                                </div>
                            </div>
                            <template v-if="filteredLocations.length">
                                <div class="col-12">
                                    <div
                                        v-for="location in filteredLocations"
                                        :key="location.id"
                                        class="store-search__list-item store-search__item-link"
                                        @click.stop="setLocation(location)"
                                    >

                                        <template v-if="location.airportIdent != 'KSK'"> <span>{{ location.name }}</span> &nbsp;-&nbsp; <span>{{ location.terminal }}, {{ location.airportIdent }}</span></template>
                                        <span v-else>{{ location.description }}</span>

                                    </div>
                                </div>
                            </template>

                            <template v-else>
                                <div class="col-12 store-search__list-item">
                                    <span class="store-search__list-item">Nothing here.</span>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import TabBar from './tab_bar'
import FormInput from './cleverly/FormInput'
import StoreSearchKeyHelper from '../mixins/store_search_key_helper'

import { mapGetters, mapActions } from 'vuex'

export default {
    name: 'StoreSearch',
    components: {
        TabBar,
        FormInput,
    },
    mixins: [
        StoreSearchKeyHelper
    ],
    data() {
        const baseAirport = {airportIdent: 'All'}
        const baseLocation = {name: 'Select Item'}

        return {
            baseLocations: [],
            query: '',
            locationTypes: [ // FIXTURED
                {name: 'Food', display: 'Food', id: 1},
                {name: 'Retail', display: 'Retail', id: 2},
                {name: 'Services', display: 'Services', id: 3},
            ],
            baseAirport,
            tmpAirport: false,
            baseLocation,
            activeLocationType: false,
            airportListEnabled: false,
            locationListEnabled: false,
        }
    },

    computed: {
        waypointId() {
            return this.$route.params.store
        },
        filteredLocations() {
            const items = this.locations

            const filters = [
                this.activeAirport.airportName && (el => {
                    if (el.airportIdent === this.activeAirport.airportIdent) {
                        return el
                    }
                }),

                this.activeLocationType.name && (el => {
                    if (el.locationType === this.activeLocationType.name) {
                        return el
                    }
                }),

                (this.query !== '') && (el => {
                    return el.name.toLowerCase().includes(this.query.toLowerCase())
                }),
            ]

            return filters
                .reduce((items, filter) => {
                    if (!filter) {
                        return items
                    }

                    return items.filter(filter)
                }, items)
        },
        activeLocation() {
            if (this.tmpAirport.airportIdent !== undefined)
                return this.baseLocation;

            return this.locations.find(el => el.waypointID == this.waypointId) || this.baseLocation;
        },
        activeAirport() {
            if (this.activeLocation.airportIdent === undefined)
                return this.tmpAirport || this.baseAirport
            
            if (this.tmpAirport.airportIdent !== undefined && this.tmpAirport.airportIdent != this.activeLocation.airportIdent)
                return this.tmpAirport

            return this.airports.find(el => el.airportIdent === this.activeLocation.airportIdent) || this.baseAirport
        },
        ...mapGetters('AirportStore', [
            'airports',
            'locations'
        ])
    },

    watch: {
        locationListEnabled() {
            this.query = ''

            Vue.nextTick(() => {
                if (this.$refs.input) {
                    this.$refs.input.$el.querySelector('input').focus()
                }
            })
        },
    },

    mounted() {
        this.getAirports()

        this.locationTypes.unshift({display: 'All'})

        document.addEventListener('keyup', e => {
            if (e.keyCode == 27) {

                if (this.airportListEnabled || this.locationListEnabled) {
                    this.closeLists()
                }
            }
        }, false)

        document.addEventListener('click', e => {
            this.closeLists()
        }, false)
    },

    methods: {
        getAirportString(airport) {
            return `${airport.airportIdent}  -  ${airport.airportName}`
        },
        setAirport(filter) {
            this.tmpAirport = filter
            this.airportListEnabled = false;
        },

        setLocation(item) {
            this.tmpAirport = {};
            this.locationListEnabled = false;
            this.$emit('locationChange', item.waypointID);
        },

        setLocationType(type) {
            this.activeLocationType = type
        },

        closeLists() {
            this.airportListEnabled = false
            this.locationListEnabled = false
        },
        ...mapActions('AirportStore', [
            'getAirports'
        ])
    },
}
</script>

<style lang="scss">
@import '../scss/variables';
@import '../scss/mixins';

.store-search-container, .store-search-content {
    @include below(sm) {
        position: static;
    }
    font-size:14px;
    padding:6px;
}

.store-search {
    &__filter-header, &__item-header {
        border: $grey_light_1 1px solid;
        padding: spacing(xs) spacing(xs) - 1px;
        cursor: pointer;
        height: 100%;
        display: flex;
        align-items: center;
        background-color: #fff;
    }

    &__filter-header {
        border-radius: spacing(md) 0 0 spacing(md);
        background-color: $grey_light_2;
        background-position: 90% center;

        @include above(sm) {
            @include background-image('', 'map_marker.svg');
        }

        &-text {
            margin-left: spacing(xs);
        }
    }

    &__item-header {
        @include background-image('', 'search_logo.svg');
        border-radius: 0 spacing(md) spacing(md) 0;
        background-position: 98% center;

        &-text {
            display: block;
            width: 90%;
            overflow-x: hidden;
            overflow-y: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            margin-left: spacing(xs);
        }
    }

    &__filter-list, &__item-list {
        top: 1px;
        left: -25px;
        width: 200%;
        max-width: 500px;

        @include above(md) {
            left: 0;
            width: 140%;
        }

        @include below(sm) {
            top: 5px;
            right: 5px;
            left: 5px;
            width: calc(100% - 10px);
        }
    }


    &__filter-list {
        border-radius: spacing(sm);
        background-color: $grey_light_2;
    }

    &__item-list {
        z-index: 1004;
        position: absolute;
        max-height: 300px;
        overflow-x: hidden;
        overflow-y: scroll;
        padding: 0;
        background-color: #fff;
        border-radius: spacing(sm);

        @include above(sm) {
            border-top-left-radius: 0;
        }
    }

    &__list-item {
        padding: 0 spacing(xs);
        height: height(6);
        display: flex;
        align-items: center;

        @include below(sm) {
            height: height(8);
            border-bottom: 1px solid #eee;
        }
    }

    &__filter-link, &__item-link {
        cursor: pointer;

        &:hover {
            background-color: darken($grey_light_2, 3%);
        }
    }

    .dropdown {
        z-index: zi(modal);
        position: absolute;
        max-height: 300px;
        overflow-y: auto;
        overflow-x: hidden;
        padding: 0;

        &__item {
            padding: spacing(xs);
            cursor: pointer;

            &:hover {
                background-color: darken($grey_light_2, 3%);
            }
        }
    }
}
</style>
