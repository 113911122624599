var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "subnav row no-gutters" },
    [
      _vm.action
        ? _vm._l(_vm.items, function (item) {
            return _c(
              "div",
              {
                key: item.id,
                class: [
                  "subnav-item",
                  "link",
                  { "subnav-item--active": item.id === _vm.activeItem },
                  ,
                  { "subnav-item--sm col-1": _vm.small },
                ],
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    _vm.action(item)
                    _vm.activeItem = item.id
                  },
                },
              },
              [_vm._v(_vm._s(item.display))]
            )
          })
        : [
            _vm.fuzzy
              ? _vm._l(_vm.items, function (item) {
                  return _c(
                    "router-link",
                    {
                      key: item.id,
                      class: [
                        "subnav-item",
                        "link",
                        { "subnav-item--sm col-1": _vm.small },
                      ],
                      attrs: {
                        to: { name: item.to, params: _vm.params },
                        activeClass: "subnav-item--active",
                      },
                    },
                    [_vm._v(_vm._s(item.display))]
                  )
                })
              : _vm._l(_vm.items, function (item) {
                  return _c(
                    "router-link",
                    {
                      key: item.id,
                      class: [
                        "subnav-item",
                        "link",
                        { "subnav-item--sm col-1": _vm.small },
                      ],
                      attrs: {
                        to: { name: item.to, params: _vm.params },
                        activeClass: "subnav-item--active",
                        exact: "",
                      },
                    },
                    [_vm._v(_vm._s(item.display))]
                  )
                }),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }