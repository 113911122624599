<template>
    <div class="subnav row no-gutters">
        <template v-if="action">
            <div
                v-for="item in items"
                :key="item.id"
                :class="['subnav-item', 'link', {'subnav-item--active': item.id === activeItem}, , {'subnav-item--sm col-1': small}]"
                @click.stop="action(item); activeItem = item.id"
            >{{ item.display }}</div>
        </template>

        <template v-else>
            <template v-if="fuzzy">
                <router-link
                    v-for="item in items"
                    :key="item.id"
                    :class="['subnav-item', 'link', {'subnav-item--sm col-1': small}]"
                    :to="{name: item.to, params}"
                    activeClass="subnav-item--active">{{ item.display }}</router-link>
            </template>

            <template v-else>
                <router-link
                    v-for="item in items"
                    :key="item.id"
                    :class="['subnav-item', 'link', {'subnav-item--sm col-1': small}]"
                    :to="{name: item.to, params}"
                    activeClass="subnav-item--active"
                    exact>{{ item.display }}</router-link>
            </template>
        </template>
    </div>
</template>

<script>
export default {
    name: 'TabBar',
    props: {
        action: {
            type: Function,
        },
        params: Object,
        items: {
            type: Array,
            required: true,
        },
        small: {
            type: Boolean,
        },
        startingTab: {
            type: [String, Number],
            default: 0,
        },
        fuzzy: Boolean,
        fixedWidth: Number,
    },

    data() {
        return {
            activeItem: this.items[this.startingTab].id,
        }
    },
}
</script>

<style lang="scss">
@import '../scss/variables';
@import '../scss/mixins';

.subnav {
    box-shadow: inset 0 -5px 0 $grey_light_1;

    &-item {
        cursor: pointer;
        text-transform: uppercase;
        text-align: center;
        padding: spacing(md);
        &--active, &:hover, &:focus {
            color: $brand_color;
            box-shadow: inset 0 -5px 0 $brand_color;
        }

        &--sm {
            padding-top: spacing(xs);
            padding-bottom: spacing(xs);
            font-size: fz(sm);
            color: $grey_dark_1;
        }
    }
}
</style>
