var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "fill-height",
      staticStyle: { "background-color": "#f9f9f9" },
    },
    [
      _c("ContextTitle", {
        attrs: {
          passedActions: _vm.titleBarActions,
          title: "Messages Templates",
        },
      }),
      _c(
        "v-card",
        {
          staticClass: "pt-3 pb-3",
          staticStyle: { "border-bottom": "1px solid #e4e4e4" },
          attrs: { width: "100%", elevation: "0" },
        },
        [
          _c(
            "div",
            { staticClass: "messages-selector__container pl-9 pr-9" },
            [
              _c("div", { staticClass: "messages-selector__title" }, [
                _vm._v("Select a waypoint to start"),
              ]),
              _c(
                "v-row",
                { staticClass: "mt-3 messages-selector__waypoint-selector" },
                [
                  _c(
                    "v-col",
                    { staticClass: "pt-0 pb-0", attrs: { md: "8", sm: "12" } },
                    [
                      _vm.selectionMode === _vm.SelectionMode.ByWaypoint
                        ? _c("store-search-new", {
                            attrs: {
                              airport: _vm.airport,
                              waypoint: _vm.waypointId,
                            },
                            on: {
                              "airport-change": function ($event) {
                                _vm.airport = $event
                              },
                              "waypoint-change": function ($event) {
                                _vm.waypointId = $event
                              },
                            },
                          })
                        : _vm._e(),
                      _vm.selectionMode === _vm.SelectionMode.ByPartner
                        ? [
                            _c("v-select", {
                              attrs: { items: _vm.partners },
                              model: {
                                value: _vm.partnerId,
                                callback: function ($$v) {
                                  _vm.partnerId = $$v
                                },
                                expression: "partnerId",
                              },
                            }),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c("v-col", [
                    _c(
                      "div",
                      {
                        staticClass:
                          "messages-selector__filter_title pt-3 pb-3 pl-1",
                      },
                      [_vm._v(" Additional filters ")]
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "v-row",
                {
                  staticClass: "messages-selector__filters pt-0 mt-0",
                  attrs: { "no-gutters": "" },
                },
                [
                  false
                    ? _c(
                        "v-col",
                        [
                          _c("v-select", {
                            attrs: {
                              label: "Selection mode",
                              items: _vm.filterOptions,
                            },
                            model: {
                              value: _vm.selectionMode,
                              callback: function ($$v) {
                                _vm.selectionMode = $$v
                              },
                              expression: "selectionMode",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-col",
                    { staticClass: "pr-3 pl-1" },
                    [
                      _c("v-select", {
                        attrs: {
                          items: _vm.platformTypeOptions,
                          loading: _vm.isMetadataLoading,
                          multiple: "",
                          attach: "",
                          outlined: "",
                          dense: "",
                          label: "Platforms",
                        },
                        model: {
                          value: _vm.selectedPlatformIds,
                          callback: function ($$v) {
                            _vm.selectedPlatformIds = $$v
                          },
                          expression: "selectedPlatformIds",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "pl-3 pr-3" },
                    [
                      _c("v-select", {
                        attrs: {
                          items: _vm.eventTypeOptions,
                          loading: _vm.isMetadataLoading,
                          multiple: "",
                          attach: "",
                          outlined: "",
                          dense: "",
                          label: "Events",
                        },
                        model: {
                          value: _vm.selectedEvents,
                          callback: function ($$v) {
                            _vm.selectedEvents = $$v
                          },
                          expression: "selectedEvents",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "pl-3 pr-1" },
                    [
                      _c("v-select", {
                        attrs: {
                          items: _vm.orderTypeOptions,
                          loading: _vm.isMetadataLoading,
                          multiple: "",
                          attach: "",
                          outlined: "",
                          dense: "",
                          label: "Order types",
                        },
                        model: {
                          value: _vm.selectedOrderTypes,
                          callback: function ($$v) {
                            _vm.selectedOrderTypes = $$v
                          },
                          expression: "selectedOrderTypes",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "pl-3 pr-1" },
                    [
                      _c("v-select", {
                        attrs: {
                          items: _vm.languageOptions,
                          loading: _vm.isCustomerSmsTemplatesLoading,
                          multiple: "",
                          attach: "",
                          outlined: "",
                          dense: "",
                          label: "Language",
                        },
                        model: {
                          value: _vm.selectedLanguages,
                          callback: function ($$v) {
                            _vm.selectedLanguages = $$v
                          },
                          expression: "selectedLanguages",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm.waypointId
        ? _c("div", { staticClass: "messages-header" }, [
            _c(
              "div",
              { staticClass: "messages-header__title pl-9 pr-9 pt-5 pb-5" },
              [_vm._v(" " + _vm._s(_vm.waypointTitle) + " ")]
            ),
          ])
        : _vm._e(),
      _c(
        "v-row",
        {
          staticClass: "messages-container pl-9 pr-9",
          attrs: { "no-gutters": "" },
        },
        [
          _c(
            "v-col",
            { staticClass: "fill-height" },
            [
              _c("v-row", { staticClass: "overflow-y-auto pl-3 pr-3" }, [
                !_vm.waypointId
                  ? _c("h5", { staticClass: "pt-3 text-center" }, [
                      _vm._v("Select a waypoint to start"),
                    ])
                  : _vm.isCustomerSmsTemplatesLoading
                  ? _c(
                      "div",
                      { staticStyle: { width: "100%" } },
                      [
                        _c("v-skeleton-loader", {
                          staticClass: "d-flex flex-column",
                          attrs: {
                            type: "table",
                            elevation: "2",
                            width: "100%",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm.filteredMessageTemplates.length === 0
                  ? _c("h5", { staticClass: "pt-3" }, [
                      _vm._v(
                        " Sorry, no message templates matches your query. "
                      ),
                    ])
                  : _c(
                      "div",
                      { staticStyle: { width: "100%" } },
                      [
                        _c("v-data-table", {
                          staticStyle: { width: "100%" },
                          attrs: {
                            headers: _vm.headers,
                            items: _vm.filteredMessageTemplateDataItems,
                            "hide-default-footer": "",
                            "item-key": "id",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: `item.platform`,
                                fn: function ({ item }) {
                                  return [
                                    _c("platform-label", {
                                      attrs: {
                                        value: item.platform,
                                        id: item.platformId,
                                      },
                                    }),
                                  ]
                                },
                              },
                              {
                                key: `item.event`,
                                fn: function ({ item }) {
                                  return [
                                    _c(
                                      "v-chip",
                                      {
                                        staticStyle: {
                                          padding: "0 3px",
                                          "text-transform": "uppercase",
                                        },
                                        attrs: {
                                          outlined: "",
                                          label: "",
                                          small: "",
                                          color: "#e040fb",
                                        },
                                      },
                                      [_vm._v(" " + _vm._s(item.event) + " ")]
                                    ),
                                  ]
                                },
                              },
                              {
                                key: `item.orderType`,
                                fn: function ({ item }) {
                                  return [
                                    _c(
                                      "v-chip",
                                      {
                                        staticStyle: {
                                          padding: "0 3px",
                                          "text-transform": "uppercase",
                                        },
                                        attrs: {
                                          outlined: "",
                                          label: "",
                                          small: "",
                                          color: "#439ED5",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(item.orderType) + " "
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                              {
                                key: `item.preview`,
                                fn: function ({ item }) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          "white-space": "nowrap",
                                          overflow: "hidden",
                                          "text-overflow": "ellipsis",
                                          "max-width": "210px",
                                        },
                                        attrs: { title: item.preview },
                                      },
                                      [_vm._v(" " + _vm._s(item.preview) + " ")]
                                    ),
                                  ]
                                },
                              },
                              {
                                key: `item.language`,
                                fn: function ({ item }) {
                                  return [
                                    _c(
                                      "v-chip",
                                      {
                                        staticStyle: {
                                          padding: "0 3px",
                                          "text-transform": "uppercase",
                                        },
                                        attrs: {
                                          outlined: "",
                                          label: "",
                                          small: "",
                                          color: "#439ED5",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(item.language) + " "
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                              {
                                key: `item.action`,
                                fn: function ({ item }) {
                                  return [
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          color: "#679436",
                                          "x-small": "",
                                          text: "",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return item.action.onEdit()
                                          },
                                        },
                                      },
                                      [
                                        _c("v-icon", { attrs: { small: "" } }, [
                                          _vm._v("edit"),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                      ],
                      1
                    ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }