import KeyBus from '../store/key_bus'
import _ from 'lodash'

export default {    
    created() {
        KeyBus.$on(KeyBus.KEY_PRESS, this.onKeyPress)
    },
    methods: {
        onKeyPress(event) {

            if (!this.airportListEnabled) return
            if (event.charCode < 97 || event.charCode > 122) return

            const firstMatch = this.airports.find(el => {
                return el.name.charAt(0).toLowerCase() === String.fromCharCode(event.charCode)
            })

            if (firstMatch === undefined) return

            const children = this.$refs.airport_dropdown_container.children
            const index = _.findIndex(children, el => {
                return el.dataset.index == firstMatch.id
            })

            this.$refs.airport_dropdown_container.scrollTop = children[index].offsetTop

        }
    },
    destoyed() {
        KeyBus.$off(KeyBus.KEY_PRESS, this.onKeyPress)
    }
}