import Vue from 'vue'

export default new Vue({
    data() {
        return {
            KEY_UP: "KEY_UP",
            KEY_DOWN: "KEY_DOWN",
            KEY_PRESS: "KEY_PRESS",
        }
    },
    created() {
        window.addEventListener('keyup', this._handleKeyUp.bind(this))
        window.addEventListener('keydown', this._handleKeyDown.bind(this))
        window.addEventListener('keypress', this._handleKeyPress.bind(this))

    },
    methods: {
        _handleKeyUp(event) {
            this.$emit(this.KEY_UP, event)
        },
        _handleKeyDown(event) {
            this.$emit(this.KEY_DOWN, event)
        },
        _handleKeyPress(event) {
            this.$emit(this.KEY_PRESS, event)

        }
    },
})
