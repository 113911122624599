var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "store-search-container col-md-12 col-9" }, [
    _c(
      "div",
      {
        staticClass:
          "store-search row no-gutters align-items-center height-xs--inherit",
      },
      [
        _c(
          "div",
          { staticClass: "store-search-content col-md-12 height-xs--6" },
          [
            _c(
              "div",
              {
                staticClass:
                  "height-xs--inherit row align-items-center no-gutters",
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "store-search__filter-header col-3",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        _vm.locationListEnabled = false
                        _vm.airportListEnabled = !_vm.airportListEnabled
                      },
                    },
                  },
                  [
                    _c(
                      "span",
                      { staticClass: "store-search__filter-header-text" },
                      [_vm._v(_vm._s(_vm.activeAirport.airportIdent))]
                    ),
                  ]
                ),
                _vm.airportListEnabled
                  ? _c(
                      "div",
                      {
                        ref: "airport_dropdown_container",
                        staticClass:
                          "store-search__filter-list col-12 shadow--2 dropdown",
                      },
                      [
                        _c(
                          "li",
                          {
                            staticClass:
                              "store-search__list-item store-search__filter-link",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.setAirport(_vm.baseAirport)
                              },
                            },
                          },
                          [
                            _c("span", [
                              _vm._v(_vm._s(_vm.baseAirport.airportIdent)),
                            ]),
                          ]
                        ),
                        _vm.airports.length
                          ? _vm._l(_vm.airports, function (airport) {
                              return _c(
                                "li",
                                {
                                  key: airport.id,
                                  staticClass:
                                    "store-search__list-item store-search__filter-link",
                                  attrs: { "data-index": airport.id },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.setAirport(airport)
                                    },
                                  },
                                },
                                [
                                  _c("div", { staticClass: "d-flex" }, [
                                    _c("div", [
                                      _vm._v(
                                        _vm._s(_vm.getAirportString(airport))
                                      ),
                                    ]),
                                  ]),
                                ]
                              )
                            })
                          : _c("div", [_vm._v(" Nothing here. ")]),
                      ],
                      2
                    )
                  : _vm._e(),
                _c(
                  "div",
                  {
                    staticClass: "store-search__item-header col-9",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        _vm.airportListEnabled = false
                        _vm.locationListEnabled = !_vm.locationListEnabled
                      },
                    },
                  },
                  [
                    _c(
                      "span",
                      { staticClass: "store-search__item-header-text" },
                      [
                        _vm._v(" " + _vm._s(_vm.activeLocation.name) + " "),
                        _vm.activeLocation.terminal
                          ? [
                              _vm._v(
                                " - " + _vm._s(_vm.activeLocation.terminal)
                              ),
                            ]
                          : _vm._e(),
                      ],
                      2
                    ),
                  ]
                ),
                _vm.locationListEnabled
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "store-search__item-list col-12 offset-md-3 shadow--2",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "row" },
                          [
                            _c("div", { staticClass: "col-12" }, [
                              _c(
                                "div",
                                { staticClass: "store-search__list-item" },
                                [
                                  _c("form-input", {
                                    ref: "input",
                                    attrs: { bare: "true", autofocus: "" },
                                    nativeOn: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return (() => true).apply(
                                          null,
                                          arguments
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.query,
                                      callback: function ($$v) {
                                        _vm.query = $$v
                                      },
                                      expression: "query",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _vm.filteredLocations.length
                              ? [
                                  _c(
                                    "div",
                                    { staticClass: "col-12" },
                                    _vm._l(
                                      _vm.filteredLocations,
                                      function (location) {
                                        return _c(
                                          "div",
                                          {
                                            key: location.id,
                                            staticClass:
                                              "store-search__list-item store-search__item-link",
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                return _vm.setLocation(location)
                                              },
                                            },
                                          },
                                          [
                                            location.airportIdent != "KSK"
                                              ? [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(location.name)
                                                    ),
                                                  ]),
                                                  _vm._v("  -  "),
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        location.terminal
                                                      ) +
                                                        ", " +
                                                        _vm._s(
                                                          location.airportIdent
                                                        )
                                                    ),
                                                  ]),
                                                ]
                                              : _c("span", [
                                                  _vm._v(
                                                    _vm._s(location.description)
                                                  ),
                                                ]),
                                          ],
                                          2
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ]
                              : [_vm._m(0)],
                          ],
                          2
                        ),
                      ]
                    )
                  : _vm._e(),
              ]
            ),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-12 store-search__list-item" }, [
      _c("span", { staticClass: "store-search__list-item" }, [
        _vm._v("Nothing here."),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }