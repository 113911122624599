import {mapActions, mapGetters} from "vuex";
import {OperationStatus} from "helpers/common";

export default {
    computed: {
        ...mapGetters("MessagingStore", [
            "eventLabelById",
            "platformLabelById",
            "orderTypeLabelById",
            "templateMetadataStatus",
            "orderTypes",
            "platformTypes",
        ]),
    },
    methods: {
        ...mapActions("MessagingStore", [
            "getMessageTemplateMetaData"
        ])
    },
    async mounted() {
        if ([OperationStatus.Unknown, OperationStatus.Failed].indexOf(this.templateMetadataStatus) > -1) {
            await this.getMessageTemplateMetaData();
        }
    }
}
