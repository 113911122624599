<template>
    <div class="fill-height" style="background-color: #f9f9f9;">
        <ContextTitle :passedActions="titleBarActions" title="Messages Templates"/>
        <v-card width="100%" class="pt-3 pb-3" elevation="0" style="border-bottom: 1px solid #e4e4e4">
            <div class="messages-selector__container pl-9 pr-9">
                <div class="messages-selector__title">Select a waypoint to start</div>
                <v-row class="mt-3 messages-selector__waypoint-selector">
                    <v-col md="8" sm="12" class="pt-0 pb-0">
                        <store-search-new
                            v-if="selectionMode === SelectionMode.ByWaypoint"
                            :airport="airport"
                            :waypoint="waypointId"
                            @airport-change="airport = $event"
                            @waypoint-change="waypointId = $event"
                        />
                        <template v-if="selectionMode === SelectionMode.ByPartner">
                            <v-select v-model="partnerId" :items="partners"/>
                        </template>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <div class='messages-selector__filter_title pt-3 pb-3 pl-1'>
                            Additional filters
                        </div>
                    </v-col>
                </v-row>
                <v-row no-gutters class="messages-selector__filters pt-0 mt-0">
                    <v-col v-if="false">
                        <v-select label="Selection mode" v-model="selectionMode" :items="filterOptions"/>
                    </v-col>
                    <v-col class="pr-3 pl-1">
                        <v-select
                            v-model="selectedPlatformIds"
                            :items="platformTypeOptions"
                            :loading="isMetadataLoading"
                            multiple
                            attach
                            outlined
                            dense
                            label="Platforms"
                        />
                    </v-col>
                    <v-col class="pl-3 pr-3">
                        <v-select
                            v-model="selectedEvents"
                            :items="eventTypeOptions"
                            :loading="isMetadataLoading"
                            multiple
                            attach
                            outlined
                            dense
                            label="Events"
                        />
                    </v-col>
                    <v-col class="pl-3 pr-1">
                        <v-select
                            v-model="selectedOrderTypes"
                            :items="orderTypeOptions"
                            :loading="isMetadataLoading"
                            multiple
                            attach
                            outlined
                            dense
                            label="Order types"
                        />
                    </v-col>
                    <v-col class="pl-3 pr-1">
                        <v-select
                            v-model="selectedLanguages"
                            :items="languageOptions"
                            :loading="isCustomerSmsTemplatesLoading"
                            multiple
                            attach
                            outlined
                            dense
                            label="Language"
                        />
                    </v-col>
                </v-row>
            </div>
        </v-card>
        <div class="messages-header" v-if="waypointId">
            <div class="messages-header__title pl-9 pr-9 pt-5 pb-5">
                {{ waypointTitle }}
            </div>
        </div>
        <v-row no-gutters class="messages-container pl-9 pr-9">
            <v-col class="fill-height">
                <v-row class="overflow-y-auto pl-3 pr-3">
                    <h5 v-if="!waypointId" class="pt-3 text-center">Select a waypoint to start</h5>
                    <div v-else-if="isCustomerSmsTemplatesLoading" style="width: 100%">
                        <v-skeleton-loader
                            type="table"
                            class="d-flex flex-column"
                            elevation="2"
                            width="100%"
                        />
                    </div>
                    <h5 v-else-if="filteredMessageTemplates.length === 0" class="pt-3">
                        Sorry, no message templates matches your query.
                    </h5>
                    <div style='width:100%;' v-else>
                        <v-data-table
                            :headers='headers'
                            :items='filteredMessageTemplateDataItems'
                            hide-default-footer
                            item-key="id"
                            style='width:100%'
                        >
                            <template v-slot:[`item.platform`]="{ item }">
                                <platform-label :value='item.platform' :id='item.platformId' />
                            </template>
                            <template v-slot:[`item.event`]="{ item }">
                                <v-chip
                                    outlined
                                    label
                                    small
                                    color='#e040fb'
                                    style='padding: 0 3px; text-transform: uppercase'
                                >
                                    {{ item.event }}
                                </v-chip>
                            </template>
                            <template v-slot:[`item.orderType`]="{ item }">
                                <v-chip
                                    outlined
                                    label
                                    small
                                    color='#439ED5'
                                    style='padding: 0 3px; text-transform: uppercase'
                                >
                                    {{ item.orderType }}
                                </v-chip>
                            </template>
                            <template v-slot:[`item.preview`]="{ item }">
                                <div style='
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    max-width: 210px;
                                    '
                                     :title='item.preview'
                                >
                                    {{ item.preview }}
                                </div>
                            </template>
                            <template v-slot:[`item.language`]="{ item }">
                                <v-chip
                                    outlined
                                    label
                                    small
                                    color='#439ED5'
                                    style='padding: 0 3px; text-transform: uppercase'
                                >
                                    {{ item.language }}
                                </v-chip>
                            </template>
                            <template v-slot:[`item.action`]="{ item }">
                                <v-btn
                                    color="#679436"
                                    x-small
                                    text
                                    @click="item.action.onEdit()"
                                >
                                    <v-icon small>edit</v-icon>
                                </v-btn>
                            </template>
                        </v-data-table>
                    </div>
                </v-row>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import Vue from 'vue';
import {mapGetters, mapActions} from "vuex";
import _ from 'lodash';
import ContextTitle from "components/context_title.vue";
import EditingModal from "components/editing_modal.vue";
import AlertDialog from "components/alert_dialog.vue";
import ActionButton from "components/action_button.vue";
import StoreSearch from "components/store_search";
import {OperationStatus} from "helpers/common";
import TemplateCardWrapper from "components/messaging/TemplateCardWrapper";
import StoreSearchNew from "components/store_search_new";
import waypointIdFromQuery from "mixins/waypointIdFromQuery";
import {convertObjectToSelectOption, getArrayFromQueryParam} from "helpers";
import metadata from 'mixins/messagingMetadata';
import PlatformLabel from 'components/messaging/platform_label';

const SelectionMode = {
    ByWaypoint: 1,
    ByPartner: 2,
};

export default {
    name: "MessagingIndex",
    data() {
        return {
            titleBarActions: [],
            filterOptions: [
                {text: "by Waypoint", value: SelectionMode.ByWaypoint},
                {text: "by Partner", value: SelectionMode.ByPartner},
            ],
            selectionMode: SelectionMode.ByWaypoint,
            partnerId: null,
            messageTemplates: [],
            selectedTemplateIds: [],
            airport: null,
            waypoint: null,
            restoreConfirmation: false,
            headers: [
                {
                    sortable: true,
                    text: "platform",
                    value: "platform",
                },
                {
                    sortable: true,
                    text: "event",
                    value: "event",
                },
                {
                    sortable: true,
                    text: "order type",
                    value: "orderType",
                },
                {
                    sortable: true,
                    text: "preview",
                    value: "preview",
                },
                {
                    sortable: true,
                    text: "language",
                    value: "language",
                },
                {
                    sortable: false,
                    text: "action",
                    value: "action",
                }
            ]
        };
    },
    components: {
        ContextTitle,
        EditingModal,
        AlertDialog,
        ActionButton,
        StoreSearch,
        TemplateCardWrapper,
        StoreSearchNew,
        PlatformLabel
    },
    mixins: [ waypointIdFromQuery, metadata ],
    computed: {
        ...mapGetters("MessagingStore", [
            "templateMetadataStatus",
            "platformTypes",
            "orderTypes",
            "eventTypes",
            "customerSmsTemplatesStatus",
            "customerSmsTemplates",
            "templateById",
            "templateDeleteStatus",
        ]),
        ...mapGetters("AirportStore", ["airports", "locations"]),
        ...mapGetters("PartnerStore", ["partnerList"]),
        SelectionMode: () => SelectionMode,
        partners() {
            return this.partnerList.map(x => ({
                text: `${x.partnerName} ${x.country}_${x.partnerShortCode}`,
                value: x.id
            }));
        },
        waypointTitle() {
            const location = this.locations.find(x => x.waypointID === this.waypointId);
            return location
                ? location.terminal
                    ? `${location.name} - ${location.terminal}, ${location.airportIdent}`
                    : `${location.name}, ${location.airportIdent}`
                : null;
        },
        isWaypointsSelected() {
            return (this.selectionMode === SelectionMode.ByWaypoint && !!this.waypointId)
                || (this.selectionMode === SelectionMode.ByPartner && !!this.partnerId);
        },
        filteredMessageTemplates() {
            return this
                .customerSmsTemplates
                // Temporary commented until bug on backend with WayPoint: 0 would be fixed
                //.filter(x => this.selectionMode !== SelectionMode.ByWaypoint || x.WaypointID === this.waypointId)
                .filter(x =>
                    (this.selectionMode === SelectionMode.ByWaypoint && (x.UsingDefault || x.WaypointID))
                    && (
                        this.selectedPlatformIds.length === 0
                        || x.PlatformTypeID === 0
                        || _.includes(this.selectedPlatformIds, x.PlatformTypeID.toString())
                    )
                    && (
                        this.selectedEvents.length === 0
                        || x.EventTypeID === 0
                        || _.includes(this.selectedEvents, x.EventTypeID.toString())
                    )
                    && (
                        this.selectedOrderTypes.length === 0
                        || x.OrderTypeID === 0
                        || _.includes(this.selectedOrderTypes, x.OrderTypeID.toString())
                    )
                    && (
                        this.selectedLanguages.length === 0
                            || _.includes(this.selectedLanguages, x.LanguageCode)
                    )
                );
        },
        filteredMessageTemplateDataItems() {
            return this.filteredMessageTemplates.map(x => ({
                id: x.TemplateID,
                platformId: x.PlatformTypeID,
                platform: this.platformLabelById(x.PlatformTypeID),
                event: this.eventLabelById(x.EventTypeID),
                orderType: this.orderTypeLabelById(x.OrderTypeID),
                preview: x.MessageBodyTemplate,
                language: x.LanguageCode,
                action: {
                    onEdit: () => {
                        this.edit({
                            templateID: x.TemplateID,
                            waypointID: this.waypointId,
                        })
                    }
                },
            }));
        },
        isMetadataLoading() {
            return this.templateMetadataStatus === OperationStatus.InProgress;
        },
        platformTypeOptions() {
            return convertObjectToSelectOption(this.platformTypes);
        },
        orderTypeOptions() {
            return convertObjectToSelectOption(this.orderTypes);
        },
        eventTypeOptions() {
            return convertObjectToSelectOption(this.eventTypes);
        },
        languageOptions() {
            return [...new Set(this.customerSmsTemplates.map(x => x.LanguageCode))];
        },
        isCustomerSmsTemplatesLoading() {
            return this.customerSmsTemplatesStatus === OperationStatus.InProgress;
        },
        selectedPlatformIds: {
            get() {
                return getArrayFromQueryParam(this.$route.query.platformIds);
            },
            set(value) {
                this.$router.replace({
                    name: "messaging", query: {
                        ...this.$route.query,
                        platformIds: value
                    }
                });
            }
        },
        selectedEvents: {
            get() {
                return getArrayFromQueryParam(this.$route.query.events);
            },
            set(value) {
                this.$router.replace({
                    name: "messaging", query: {
                        ...this.$route.query,
                        events: value
                    }
                });
            }
        },
        selectedOrderTypes: {
            get() {
                return getArrayFromQueryParam(this.$route.query.orderTypes);
            },
            set(value) {
                this.$router.replace({
                    name: "messaging", query: {
                        ...this.$route.query,
                        orderTypes: value
                    }
                });
            }
        },
        selectedLanguages: {
            get() {
                return getArrayFromQueryParam(this.$route.query.languages);
            },
            set(value) {
                this.$router.replace({
                    name: "messaging", query: {
                        ...this.$route.query,
                        languages: value
                    }
                });
            }
        },
    },
    async mounted() {
        await Promise.all([this.getMessageTemplateMetaData(), this.debounced_getCustomerSMSMessageTemplates(this.waypointId)]);
        await this.fetchReceiptTemplates(this.waypointId);
    },
    created() {
        this.debounced_getCustomerSMSMessageTemplates = _.debounce(this.getCustomerSMSMessageTemplates);
    },
    methods: {
        ...mapActions("MessagingStore", [
            "getCustomerSMSMessageTemplates",
            "getMessageTemplateMetaData",
            "resetTemplate",
            "bulkResetTemplates"
        ]),
        ...mapActions("PartnerStore", [
            "loadAllPartnerInformation"
        ]),
        ...mapActions("ReceiptsStore", [
            "fetchReceiptTemplates",
        ]),
        onLocationChange(waypointId) {
            this.waypointId = waypointId;
        },
        waypointLabelById(id) {
            const waypoint = this.locations.find(x => x.waypointID === id);
            return waypoint ? `${waypoint.name}-${waypoint.terminal}, ${waypoint.airportIdent}` : `Unknown waypoint with id ${id}`;
        },
        edit({ templateID, waypointID, }) {
            this.$router.push({
                name: `messaging-edit`,
                params: {
                    templateID,
                    waypointID,
                },
            });
        },
        async reset({ templateID, languageCode }) {
            await this.resetTemplate({templateID, languageCode, waypointId: this.waypointId});
            await this.getCustomerSMSMessageTemplates(this.waypointId);
        },
    },
    watch: {
        waypointId(value) {
            this.debounced_getCustomerSMSMessageTemplates(value);
        },
    }
}

</script>

<style lang="scss" scoped>
@import "../../scss/variables";
@import "../../scss/mixins";
$internalContentMaxWidth: 800px;
.messages-selector {
    &__title {
        font-weight: bold;
        color: $grey_dark_1;
        font-size: fz(md);
    }
    &__container {
        max-width: $internalContentMaxWidth;
    }
    &__filter_title {
        font-weight: bold;
        font-size: fz(sm);
        color: $grey_dark_1;
    }
}
.messages-header {
    max-width: $internalContentMaxWidth;

    &__title {
        font-weight: bold;
        font-size: fz(venti);
        color: $grey_dark_1;
    }
    &__subtitle{
        font-weight: bold;
        color: $grey_dark_1;
        font-size: fz(md);
    }
    &__reset-confirmation {
        font-size: fz(sm);
    }
}
.messages-container {
    max-width: $internalContentMaxWidth;
}
</style>
